import React from "react"

import "../../../utils/fontawesome"

import Section from "../../../components/section"
import SkillsContainer from "../../../components/skillsContainer"
import SkillCard from "../../../components/skillCard"

export default function Skills() {
  return (
    <Section
      title="What Can I Do?"
      statement="Here are a few things I do really well."
    >
      <SkillsContainer>
        <SkillCard header="Front-End" icon="palette">
          I'm an experienced <strong>JavaScript</strong> developer that uses
          frameworks like <strong>Angular</strong>, <strong>React</strong>, and{" "}
          <strong>Vue.js</strong>. I also take advantage of the popular{" "}
          <strong>CSS</strong> prerocessor <strong>SCSS</strong> when developing
          beautiful Single Page Applications.
        </SkillCard>
        <SkillCard header="Back-End" icon="power-off">
          On the back-end I have experience setting up servers using{" "}
          <strong>Java</strong> and
          <strong>C#</strong>. My favorite back-end technologies to use though
          are <strong>Node</strong> and
          <strong>Express.js</strong>.
        </SkillCard>
        <SkillCard header="DevOps" icon="cloud">
          When it comes time to finally deploy an application I have experience
          with <strong>Linux</strong>, <strong>Firebase</strong>,{" "}
          <strong>Heroku</strong> and <strong>Digital Ocean</strong>. I've also
          been exploring the use of <strong>Docker</strong> for a more fluid
          experience deploying applications.
        </SkillCard>
      </SkillsContainer>
    </Section>
  )
}
