import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import skillCardStyles from "./skillCard.module.css"

export default function SkillCard({ children, header, icon }) {
  return (
    <div className={skillCardStyles.skillCard}>
      <div className={skillCardStyles.icon}>
        <FontAwesomeIcon icon={icon} size="xs" />
      </div>
      <div className={skillCardStyles.title}>{header}</div>
      <div className={skillCardStyles.content}>{children}</div>
    </div>
  )
}
