import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faCoffee,
  faCloud,
  faPowerOff,
  faPalette,
} from "@fortawesome/free-solid-svg-icons"

library.add(faCoffee)
library.add(faCloud)
library.add(faPowerOff)
library.add(faPalette)
